// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("./../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-landing-page-landing-page-jsx": () => import("./../src/pages/LandingPage/LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-page-landing-page-jsx" */),
  "component---src-pages-landing-page-sections-initiatives-section-jsx": () => import("./../src/pages/LandingPage/Sections/InitiativesSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-initiatives-section-jsx" */),
  "component---src-pages-landing-page-sections-product-section-jsx": () => import("./../src/pages/LandingPage/Sections/ProductSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-product-section-jsx" */),
  "component---src-pages-landing-page-sections-team-section-jsx": () => import("./../src/pages/LandingPage/Sections/TeamSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-team-section-jsx" */),
  "component---src-pages-landing-page-sections-work-section-jsx": () => import("./../src/pages/LandingPage/Sections/WorkSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-work-section-jsx" */)
}

